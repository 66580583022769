exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-tiktok-account-page-js": () => import("./../../../src/components/tiktokAccountPage.js" /* webpackChunkName: "component---src-components-tiktok-account-page-js" */),
  "component---src-components-tiktok-follower-5-js": () => import("./../../../src/components/tiktok-follower-5.js" /* webpackChunkName: "component---src-components-tiktok-follower-5-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-post-mdx-slug-js": () => import("./../../../src/pages/post/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-post-mdx-slug-js" */)
}

